import React from "react";
import { Row, Col } from "react-bootstrap"
import HTMLReactParser from "html-react-parser";
import { Link } from "@StarberryUtils";
import { GetURL } from "../../common/site/functions";
import "./FooterWidget.scss";

const FooterWidget = ({ footerWidget }) => {
  const addTrailingSlash = (url) => {
    return url.endsWith('/') ? url : `${url}/`;
};
  return (
    <div className="footer-widget">
      <h6 className="text-md-bold">{footerWidget?.Widget_Title}</h6>
      <div className="footer-content text-sm">
        {footerWidget?.Widget_Content &&
          HTMLReactParser(footerWidget?.Widget_Content)}
      </div>
      <div className="footer-buttons">
        <Link
         // href={`/${GetURL(footerWidget?.Button1_Link.id)}`}
          href={addTrailingSlash(`/${GetURL(footerWidget?.Button1_Link.id)}`)}
          className="btn btn-primary"
        >
          {footerWidget?.Button1_Label}
        </Link>
        <Link
         // href={`/${GetURL(footerWidget?.Button2_Link.id)}`}
          href={addTrailingSlash(`/${GetURL(footerWidget?.Button2_Link.id)}`)}
          className="btn btn-secondary"
        >
          {footerWidget?.Button2_Label}
        </Link>
      </div>
    </div>
  );
};

export default FooterWidget;