import React, { useState } from "react";
import ModalTiny from "react-bootstrap/Modal";
import BookForm from "../forms/book-a-viewing-form";
import HTMLReactParser from "html-react-parser";
import { CloseIcon } from "../Icons/Icons";
import "./HeaderTop.scss";

const HeaderTop = ({ siteData }) => {
  const [modalBookViewformOpen, setBookViewingformOpen] = useState(false);

  const openBookViewformModal = () => setBookViewingformOpen(true);
  const closeBookViewformModal = () => setBookViewingformOpen(false);

  return (
    <>
      <div className="header-top">
        {siteData?.Header_Bar_Text && (
          <div className="top-text text-xxs">
            <span className="d-none d-md-flex">
               {HTMLReactParser(siteData?.Header_Bar_Text)}
            </span>
            <span className="d-flex d-md-none">
              {HTMLReactParser(siteData?.Header_Bar_Text_Mobile)}
            </span>
            <button className="text-xxs-bold text-underline" onClick={openBookViewformModal}>
              Book Now
            </button>
          </div>
        )}
      </div>

      {/* Modal */}
      <ModalTiny show={modalBookViewformOpen} onHide={closeBookViewformModal} className="book-a-viewing-moda modal-form-wrapper">      
        <ModalTiny.Header>  
          <div class="modal-close-btn" onClick={closeBookViewformModal}><CloseIcon /></div>        
          <ModalTiny.Title>
            <h4>Book an Appointment</h4> </ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body>
        <BookForm />
        </ModalTiny.Body>
      </ModalTiny>
    </>
  );
};

export default HeaderTop;