import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import { useLocation } from "@reach/router"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from 'jquery'
import { CallIconwhite, EmailIconWhite, WhatsAppIconWhite } from "../Icons/Icons";

import axios from "axios"
import * as qs from "query-string"
import Dacha from "../../images/dacha.png"
import "./assets/styles/ViewingForm.scss";
import "./assets/styles/Form.scss";



function BookAView(props) {
  const [startDate, setStartDate] = useState();
  const location = useLocation();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
    {
      element: "config",
      formname: "Book Appointment",
      form_type: "contact",
      error_text: "Highlighted fields are required | invalid",
      success_text: "Thank you for contacting Dacha. A member of our team will contact you shortly. ",
      email_temp_user: "book_a_viewing_user",
      email_temp_admin: "book_a_viewing_admin",
      email_server_func: "book_a_viewing",
      event_tracking: "book_a_appointment",
      page_url: "/general-enquiry"
    },

    {
      grpmd: "12",
      label: "Name*",
      placeholder: "Your Name *",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      label: "Email*",
      placeholder: "Your Email Address *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      label: "Telephone",
      placeholder: "Your Phone Number *",
      name: "telephone",
      type: "number",
      element: "input",
      required: true,
      fieldClass: "",
      label: "Phone Number*",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      label: "Enquiry Type*",
      placeholder: "Select Enquiry Type *",
      name: "enquiry_type",
      id: "enquiry_type",
      event_tracking_decider: "enquiry_type",
      element: "select",
      required: true,
      type: "select",
      step: "stepfive",
      class: "select-box",
      labelClass: "text-xs-bold",
      values: ['Viewings', 'Valuations', 'Consultation', 'Property Management', 'Mortgages', 'Other'],
    },
    {
      grpmd: "12",
      label: "Select Time & Date*",
      placeholder: "Select Time & Date *",
      name: "date_time",
      type: "date_time",
      element: "input",
      // min: today,
      // max: maxday.toString(),
      required: true,
      patternchk: "",
      labelClass: "text-xs-bold",
      class: "form-control"
    },
    {
      grpmd: "12",
      label: "Notes",
      placeholder: "Add in your notes",
      name: "message",
      element: "textarea",
      class: "",
      rows: "2",
      labelClass: "text-xs-bold",
    },
    {
      grpmd: "12",
      name: "Send Enquiry",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-4 submit-btn-wrap",
      class: "btn-primary",
      formclass: "banner_submit_btn form-submit-btn"
    },      
    {
      text: 'By clicking Send Enquiry, you agree to our <a class="content-link text-xxs">Terms & Conditions</a> and <a href="/privacy-policy/" class="content-link text-xxs">Privacy Policy</a>',
      element: "html",
      class: "frmbtmtext text-xxs col-12"
    },
    {
      element: "captcha",
      class: "captcha-class",
      captchaRef: recaptchaRef
    },
    {
      name: "negotiatoremail",
      type: "hidden",
      element: "input",
      defaultValue: props.subject?.negotiator?.Email
    }
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formvalues['g-recaptcha-response'] = token;

        formvalues['extra'] = JSON.stringify({
          formname: formvalues.formname,
          message: formvalues.message,
          enquiry_type: formvalues.enquiry_type,
          form_type: formvalues.form_type,
          date_time: formvalues.date_time
        });

        formData.append('data', JSON.stringify(formvalues));


        postFormData(formData).then(async apiRes => {


          window.grecaptcha.reset()

          // lets send mail
          formvalues['email_subject_user'] = fields[0].formname;
          formvalues['email_subject_admin'] = fields[0].formname;
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        });


        const axiosOptions_netlify = {
          url: fields[0].page_url,
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(formvalues),
        }

        // axios(axiosOptions_netlify)
        //   .then(response => {
        //     console.log('data stored')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );
        const url = typeof window !== 'undefined' ? window.location.href : ''
        if (url.indexOf("properties") > -1) {
          fields[0].formname = 'Book a Viewing';
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        if (props.subject?.isProductDetail) {
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': formvalues.enquiry_type,
            'formId': 'form-book_a_viewing',
            'formName': `Book a Viewing`,
            'formLabel': `Book a Viewing`,
          });
        } else {
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': formvalues.enquiry_type,
            'formId': 'form-' + fields[0].event_tracking,
            'formName': fields[0].formname,
            'formLabel': fields[0].formname
          });
        }


        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['property_id'] = props.subject.property_id ? props.subject.property_id : '';
      json['property_url'] = props.subject.page_url ? props.subject.page_url : '';
      json['property_title'] = props.subject.property_title ? props.subject.property_title : '';
      json['property_img'] = props.subject.property_img ? props.subject.property_img : '';
      json['property_list'] = props.subject.properties ? props.subject.properties : '';
      json['g-recaptcha-response'] = token;

      setFormvalues(json);
      setToken("test");
      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate()
    }
  };

  var location_link = process.env.GATSBY_SITE_URL+location.pathname;

  var whatsapp_no = props.subject.negotiator_details?.Phone ? props.subject.negotiator_details?.Phone.replace(/ /g,'').replace(/\+/g,'') : "9710505313003"

	var whatsapp_link = `https://wa.me/${whatsapp_no}?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
	if(location.search.indexOf("utm_source") > 0){
		whatsapp_link = `https://wa.me/${whatsapp_no}?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
	}

  return (
    <div className="book-a-viewing-form book-form">
      <div ref={myRef} />
      <div className="book-form">

        {showerror && <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>}

        {showthankyou && <div className="alert-success">
          <p>{fields[0].success_text}</p>
        </div>}

        <div className="row">
          <div className={`${(props.subject?.isProductDetail) ? "col-md-7 pl-0" : "col-md-12 px-0"} form-col-left`}>
            <Form className="form-wrap book-viewing-form-modal" name={fields[0].formname} method="post" noValidate validated={validated} onSubmit={handleSubmit} >
              <input type="hidden" name="form_name" value={fields[0].formname} />
              <input type="hidden" name="form_type" value={fields[0].form_type} />
              <input type="hidden" name="bot-field" />
              <div className="step-block">
                {fields.map((field, index) => {
                  if ("input" === field.element) {
                    return (
                      <InputField
                        name={field.name}
                        grpmd={field.grpmd}
                        ref={field.ref}
                        type={field.type}
                        startDate={field.type === 'date_time' ? startDate : ''}
                        setStartDate={field.type === 'date_time' ? setStartDate : ''}
                        fieldClass={field.class}
                        placeholder={field.placeholder}
                        label={field.label}
                        labelClass={field.labelClass}
                        required={field.required}
                        key={`${field.element}~${index}`}
                        pattern={field.patternchk}
                        handlechange={handlechange}
                      />
                    );
                  }
                  if ("select" === field.element) {
                    return (
                      <SelectField
                        name={field.name}
                        grpmd={field.grpmd}
                        ref={field.ref}
                        required={field.required}
                        fieldClass={field.class}
                        placeholder={field.placeholder}
                        label={field.label}
                        labelClass={field.labelClass}
                        values={field.values}
                        key={`${field.element}~${index}`}
                        handlechange={handlechange}
                        componentprops={props}
                      />
                    );
                  }
                  if ("textarea" === field.element) {
                    return (
                      <TextAreaField
                        name={field.name}
                        grpmd={field.grpmd}
                        ref={field.ref}
                        rows={field.rows}
                        fieldClass={field.class}
                        label={field.label}
                        labelClass={field.labelClass}
                        placeholder={field.placeholder}
                        required={field.required}
                        key={`${field.element}~${index}`}
                        handlechange={handlechange}
                      />
                    );
                  }
                  if ("checkbox" === field.element) {
                    return (
                      <CheckboxField
                        name={field.name}
                        ref={field.ref}
                        value={field.value}
                        fieldClass={field.class}
                        placeholder={field.placeholder}
                        label={field.label}
                        labelClass={field.labelClass}
                        required={field.required}
                        key={`${field.name}~${index}`}
                        handlechange={handlechange}
                      />
                    );
                  }
                  if ("radio" === field.element) {
                    return (
                      <RadioField
                        name={field.name}
                        ref={field.ref}
                        value={field.value}
                        fieldClass={field.class}
                        checked={field.checked}
                        placeholder={field.placeholder}
                        label={field.label}
                        labelClass={field.labelClass}
                        required={field.required}
                        key={`${field.name}~${index}`}
                        handlechange={handlechange}
                        lastchild={field.lastchild}
                      />
                    );
                  }
                  if ("html" === field.element) {
                    return (
                      <HtmlBox
                        text={field.text}
                        fieldClass={field.class}
                        key={`${field.element}~${index}`}
                      />
                    );
                  }
                  if ("captcha" === field.element) {
                    return (
                      <ReCaptchaBox
                        fieldClass={field.class}
                        captRef={field.captchaRef}
                        key={`${field.element}~${index}`}
                        handleonVerify={handleonVerify}
                      />
                    );
                  }
                  if ("button" === field.element) {
                    return (
                      <ButtonField
                        name={field.name}
                        fieldClass={field.class}
                        step={field.step}
                        formclass={field.formclass}
                        type={field.type}
                        value={field.value}
                        key={`${field.element}~${index}`}
                      />
                    );
                  }
                })
                }
              </div>
            </Form>
          </div>
          {props.subject && props.subject?.isProductDetail &&
            <div className="col-md-5 property-details">
              
              <div className="product-info">
                <img loading="lazy" src={props.subject.property_img} />
                <h6>{props.subject.property_price}</h6>                
                <p>{props.subject.negotiator}</p>
              </div>
                <div className="agent-info">
                  <div className="agent-pic">
                    {props.subject.negotiator_details ?
                      <img loading="lazy" src={props.subject.negotiator_details?.Tile_Image.url} alt={`${props.subject.negotiator_details?.Name} ${props.subject.negotiator_details?.Designation} - Dacha`} />
                      :
                      <img loading="lazy" src={Dacha} alt={`Dacha Logo`} />
                    }
                  </div>
                  <div className="agent-data">
                  <h6 className="text-sm-bold">{props.subject.negotiator_details?.Name}</h6>
                  <p className="text-xxs"> {props.subject.negotiator_details?.Designation} </p>
                  </div>
                  </div>
                  <div className="agent-contact-info">
                      {props.subject.negotiator_details?.Phone ?
                      <a href={`tel:${props.subject.negotiator_details?.Phone}`} className="agent-number text-xxs btn btn-primary"><span><CallIconwhite className="icon-contact-info" /></span><span>Call</span></a>
                      :
                      <a href={`tel:+971 4 423 2006`} className="agent-number  btn btn-primary"><span><CallIconwhite className="icon-contact-info" /></span><span>Call</span></a>
                     }
                     <a href={`mailto:${props.subject.negotiator_details?.Email}`} className="agent-number text-xxs btn btn-primary"><span><EmailIconWhite className="icon-contact-info" /></span><span>Email</span></a>
                      {props.subject.negotiator_details?.Phone ?
                        <a href={whatsapp_link} className="agent-number agent-whatsapp btn btn-primary" target="_blank"><span><WhatsAppIconWhite className="icon-contact-info" /></span><span>WhatsApp</span></a>
                        :
                        <a href={whatsapp_link} className="agent-number agent-whatsapp btn btn-primary" target="_blank" ><span><WhatsAppIconWhite className="icon-contact-info" /></span><span>WhatsApp</span></a>
                      }
					        </div>
              </div>
          }
        </div>
      </div>
    </div>
  );
}


const BookAViewForm = (props) => (
  <BookAView subject={props} />
)

export default BookAViewForm