import React from "react";
import { Row, Col } from "react-bootstrap";
import FooterMenu from "./FooterMenu";
import FooterWidget from "./FooterWidget";
import NewsLetter from "../../Home/NewsLetter/NewsLetter";
import "./FooterTop.scss";

const FooterTop = ({ menus, footerWidget }) => {
  return (
    <div className="footer-top-wrap">
      <Row>
        <Col xl={8} sm={12} className="footer-menu-wrapper">
          <FooterMenu menus={menus} />
        </Col>
        {/*<Col xl={4} sm={12} className="footer-widget-wrapper">
           <FooterWidget footerWidget={footerWidget} /> 
        </Col>*/}
        <Col xl={4} sm={12} className="footer-newsletter-wrapper">
          <NewsLetter />
        </Col>
      </Row>
    </div>
  );
};

export default FooterTop;