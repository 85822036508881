import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Loadable from "@loadable/component";
import "./NewsLetter.scss";

const NewsletterFormPage = Loadable(() => import("../../forms/newsletter-form"));

const NewsLetter = () => {
  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    // Handler function to set renderComponent to true
    const handleUserInteraction = () => {
      if (!renderComponent) {
        setRenderComponent(true);
      }
    };

    // Attach event listeners
    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keypress", handleUserInteraction);
    window.addEventListener("touchmove", handleUserInteraction);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keypress", handleUserInteraction);
      window.removeEventListener("touchmove", handleUserInteraction);
    };
  }, [renderComponent]); // Only re-run effect if renderComponent changes

  return (
    <React.Fragment>
      <section className="news-letter-wrapper">
        <Container>
          <div className="newsletter-info">
            <h6 className="text-md-bold">Sign up for Newsletter</h6>
            {renderComponent && <NewsletterFormPage />}
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default NewsLetter;